import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigKeys } from '../../../../shared/interfaces/config.interface';
import { ConfigService } from '../../../../shared/services/config.service';
import { downloadFile } from '../../../shared/utils/export.utils';

@Injectable({ providedIn: 'root' })
export class WebServiceLogsService {
  private basePath = 'http://localhost:3333/api';

  constructor(private readonly httpClient: HttpClient, private readonly config: ConfigService) {
    this.basePath = config.get(ConfigKeys.API_BASE_PATH);
  }
  public async downloadWebServiceLogData(operationName: string, correlationId: string, timeStamp: string) {
    const url = `${this.basePath}/webservice-logs/${correlationId}/logs`;
    const response = await this.httpClient.get(url, { responseType: 'text' }).toPromise();
    const fileName = `${operationName}_${timeStamp}_logs_techniques.txt`;

    downloadFile(fileName, response);
  }

  public async downloadWebServiceLogParameters(operationName: string, correlationId: string, timeStamp: string) {
    const url = `${this.basePath}/webservice-logs/${correlationId}/parameters`;
    const response = await this.httpClient.get(url, { responseType: 'text' }).toPromise();
    const fileName = `${operationName}_${timeStamp}_paramètres.txt`;

    downloadFile(fileName, response);
  }
}
