import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../modules/guards/auth.guard';
import { TermsGuard } from '../modules/guards/terms.guard';
import { VerticalLayoutComponent } from '../modules/layout/components/smart/vertical-layout/vertical-layout.component';
import { LoginPageComponent } from '../pages/login-page/login-page.component';
import { RouteResolver } from '../shared/resolvers/route.resolvers';
import { GenericRoutes } from '../shared/typings/generic-routes';
import { LayoutComponent } from './../modules/layout/components/smart/layout/layout.component';
import { AssociationsPageComponent } from './pages/associations-page/associations-page.component';
import { DeliveryNotePageComponent } from './pages/delivery-note-page/delivery-note-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { IndicatorsPageComponent } from './pages/indicators-page/indicators-page.component';
import { ProductReferentialPageComponent } from './pages/product-referential-page/product-referential-page.component';
import { ReceivedDeliveryNotesPageComponent } from './pages/received-delivery-notes-page/received-delivery-notes-page.component';
import { StatistiquesPageComponent } from './pages/statistuques-page/statistiques-page.component';
import { WebServiceLogsPageComponent } from './pages/webservice-logs-page/webservice-logs-page.component';
import { CustomRoutes } from './typings/custom-routes.enum';
import { PermissionAction } from './typings/permission-action.enum';
import { PermissionSubject } from './typings/permission-subject.enum';

const homeRoute: Route = {
  path: '',
  component: VerticalLayoutComponent,
  canActivate: [AuthGuard, TermsGuard],
  children: [
    {
      path: '',
      component: HomePageComponent,
      pathMatch: 'full',
    },
    {
      path: 'home',
      component: HomePageComponent,
      pathMatch: 'full',
    },
  ],
  resolve: { route: RouteResolver },
  data: {
    // Same permissions must be applied here that the route of the dashboard component.
    // We need to check the connected user has the rights to consult the default landing page.
    useResponsiveOptions: true,
    permissions: [
      {
        action: PermissionAction.Dashboard,
        subject: PermissionSubject.All,
      },
    ],
  },
};

const routes: Routes = [
  {
    path: GenericRoutes.Login,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard],
    children: [{ path: '', component: LoginPageComponent, pathMatch: 'full' }],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
  {
    ...homeRoute,
  },
  {
    ...homeRoute,
    path: 'amsextranet',
    children: [{ path: '**', redirectTo: '' }],
  },
  {
    path: CustomRoutes.Associations,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: AssociationsPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.ListAssociations,
          subject: PermissionSubject.All,
        },
      ],
    },
  },
  {
    path: CustomRoutes.ValidateIndicateurs,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: IndicatorsPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
  {
    // Default permission failure landing page. Path must be the same as config key defaultForbiddenRoute.
    // We are not in a class, we cannot use configService to load the value.
    path: CustomRoutes.AccesNonAutorise,
    component: LayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: ForbiddenPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
  {
    path: CustomRoutes.DownloadRepository,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      {
        path: '',
        component: ProductReferentialPageComponent,
        pathMatch: 'full',
      },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
    },
  },
  {
    path: CustomRoutes.ListeBl,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: DeliveryNotePageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.ListBonLivraison,
          subject: PermissionSubject.All,
        },
      ],
    },
  },
  {
    path: CustomRoutes.ListBlRecu,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      {
        path: '',
        component: ReceivedDeliveryNotesPageComponent,
        pathMatch: 'full',
      },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.ListBonLivraison,
          subject: PermissionSubject.All,
        },
      ],
    },
  },
  {
    path: CustomRoutes.Statisiques,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: StatistiquesPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      useResponsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.ListStatistics,
          subject: PermissionSubject.All,
        },
      ],
    },
  },
  {
    path: CustomRoutes.MonitoringWebServices,
    component: VerticalLayoutComponent,
    canActivate: [AuthGuard, TermsGuard],
    children: [
      { path: '', component: WebServiceLogsPageComponent, pathMatch: 'full' },
    ],
    resolve: { route: RouteResolver },
    data: {
      userRepoonsiveOptions: true,
      permissions: [
        {
          action: PermissionAction.ListWebServiceLogs,
          subject: PermissionSubject.All,
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomRoutingModule {}
