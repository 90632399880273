import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TcButtonsModule } from '@tc/buttons';
import { TcCoreModule } from '@tc/core';
import { TcSmartFormModule } from '@tc/smart-form';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from '../../shared/shared.module';
import { WebServiceLogsGridFilterComponent } from './components/webservice-logs-grid-filter/webservice-logs-grid-filter.component';
import { WebServiceLogsGridComponent } from './components/webservice-logs-grid/webservice-logs-grid.component';
import { WebServiceLogsEffects } from './store/webservice-logs.effects';

const components = [
  WebServiceLogsGridComponent,
  WebServiceLogsGridFilterComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TcButtonsModule,
    TcSmartFormModule,
    TcCoreModule,
    SharedModule,
    AgGridModule,
    EffectsModule.forFeature([WebServiceLogsEffects]),
  ],
  exports: [...components],
})
export class WebServiceLogsModule {}
