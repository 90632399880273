import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TcConfigTypes, TcDataProviderType, TcSmartFilterConfig } from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import { DEFAULT_TC_FILTER_STATE_KEY, TcFormlyComponent, TcTranslateService, formlyColumn, formlyControl, formlyRow } from '@tc/core';
import { NgRxTcDataState } from '@tc/data-store';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { hasValue } from '@tc/utils';
import moment from 'moment';
import { Observable, Subscription, distinctUntilChanged, filter } from 'rxjs';
import { DEFAULT_GRID_HEADER_HEIGHT, GRID_ROW_HEIGHT_SMALL } from '../../../../shared/utils/constants';
import { downloadWebServiceParametersData, downloadWebServiceLogData } from '../../store/webservice-logs.actions';

@Component({
  selector: 'app-webservice-logs-grid',
  templateUrl: 'webservice-logs-grid.component.html',
  styleUrls: ['webservice-logs-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebServiceLogsGridComponent extends TcSmartGridComponent implements OnInit, OnDestroy {
  storeKey = 'webservice-logs-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  private filterStore$: Observable<NgRxTcDataState>;
  private subscription = new Subscription();

  constructor(store$: Store<any>, private readonly _routeProvider: ITcRestApiRoutesProvider, private readonly translateService: TcTranslateService) {
    super(store$);
    this.filterStore$ = store$.pipe(select(DEFAULT_TC_FILTER_STATE_KEY), filter(hasValue), distinctUntilChanged());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this._setFilterConfig();
    this._setListConfig();
    super.ngOnInit();
  }

  private _setFilterConfig() {
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'startDate',
                type: TcFormlyComponent.TcDatePicker,
                defaultValue: this._getFilterDate(7)
              }),
              formlyControl({
                key: 'endDate',
                type: TcFormlyComponent.TcDatePicker,
                defaultValue: this._getFilterDate(0)
              }),
              formlyControl({
                key: 'operationResult',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: false,
                colSpan: 2,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: '',
                      label: this.translateService.instant(`webservice-logs-grid.filter.values.tous`)
                    },
                    {
                      value: true,
                      label: this.translateService.instant(`webservice-logs-grid.filter.values.success`)
                    },
                    {
                      value: false,
                      label: this.translateService.instant(`webservice-logs-grid.filter.values.fail`)
                    }
                  ]
                }
              })
            ]
          })
        ]
      })
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false
    };
  }

  private _getFilterDate(numberOfDaysInThePast = 0) {
    const today = new Date();

    // Get the date of today minus the number of days in the past, at 00:00:00
    today.setDate(today.getDate() - numberOfDaysInThePast);

    const momentDate = moment(today);

    return momentDate.format('YYYY-MM-DDT00:00:00');
  }

  private _setListConfig() {
    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'webservice-logs-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: GRID_ROW_HEIGHT_SMALL
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this._routeProvider.getRoutes().webserviceLogsGrid
      },
      columnNumberPerDevice: {
        extraSmallDevice: 20,
        smallDevice: 20,
        mediumDevice: 20,
        largeDevice: 20,
        extraLargeDevice: 20,
        extraExtraLargeDevice: 20
      },
      columns: [
        {
          field: 'operationResult',
          minWidth: 115,
          maxWidth: 115,
          resizable: false,
          cellClassRules: {
            'operation-result-success': params => params.data.operationResult === true,
            'operation-result-fail': params => params.data.operationResult === false
          },
          cellRenderer: params => this.translateService.instant(`webservice-logs-grid.operation-result.value.${params.value}`)
        },
        {
          field: 'date',
          minWidth: 90,
          maxWidth: 90,
          resizable: false,
          cellRenderer: params => moment(params.data.timeStamp).format('DD/MM/YYYY')
        },
        {
          field: 'time',
          minWidth: 90,
          maxWidth: 90,
          resizable: false,
          cellRenderer: params => moment(params.data.timeStamp).format('HH:mm:ss')
        },
        {
          field: 'description',
          minWidth: 200,
          resizable: false,
          cellRenderer: params => this._descriptionCellRenderer(params.value)
        },
        {
          field: 'operationName',
          minWidth: 150,
          maxWidth: 150,
          resizable: false
        },
        {
          field: 'operationDurationMs',
          comparator: (valueA, valueB, nodeA, nodeB, isDescending) => (isDescending ? -1 : 1),
          minWidth: 90,
          maxWidth: 90,
          resizable: false,
          cellRenderer: params => moment.utc(moment.duration(params.value).asMilliseconds()).format('HH:mm:ss')
        },
        {
          field: 'exception',
          minWidth: 110,
          maxWidth: 110,
          resizable: false,
          sortable: false,
          cellClass: 'link-cell',
          onCellClicked: params => this._handleErrorMessageDownload(params.data),
          cellRenderer: params => this._getErrorMessageCellRenderer(params)
        },
        {
          field: 'hasParameters',
          minWidth: 110,
          maxWidth: 110,
          resizable: false,
          sortable: false,
          cellClass: 'link-cell',
          onCellClicked: params => this._handleOperationParametersDownload(params.data),
          cellRenderer: params => this._getOperationParametersCellRenderer(params)
        },
        {
          field: 'operationLogs',
          minWidth: 130,
          maxWidth: 130,
          resizable: false,
          sortable: false,
          cellClass: 'link-cell',
          onCellClicked: params => this._handleOperationLogsDownload(params.data),
          cellRenderer: params => this._getOperationLogsCellRenderer(params)
        }
      ],
      filterConfig: this.filterConfig
    };
  }

  private _descriptionCellRenderer(description: string) {
    return `
      <span class="description-cell" title="${description}">${description}</span>
    `;
  }

  private _getErrorMessageCellRenderer(params) {
    if (params.value) {
      return `
      <span class="link-cell">
        ${this.translateService.instant('webservice-logs-grid.exception.cell-value')}
      </span>`;
    }
    return '';
  }

  private _getOperationParametersCellRenderer(params) {
    if (params.value) {
      return `<span class="link-cell">${this.translateService.instant('webservice-logs-grid.operation-parameters.cell-value')}</span>`;
    }
    return '';
  }

  private _getOperationLogsCellRenderer(params) {
    return `<span class="link-cell">${this.translateService.instant('webservice-logs-grid.operation-logs.cell-value')}</span>`;
  }

  private _handleErrorMessageDownload(logEntry) {
    if (!logEntry.exception?.length) {
      // There are no parameters to download
      return;
    }

    this._downloadFile(logEntry.exception, `${logEntry.operationName}_${logEntry.timeStamp}_erreur`, 'txt', 'text');
  }

  private _handleOperationParametersDownload(logEntry) {
    if (!logEntry.hasParameters) {
      return;
    }

    this.store$.dispatch(
      downloadWebServiceParametersData({
        correlationId: logEntry.correlationId,
        operationName: logEntry.operationName,
        timeStamp: logEntry.timeStamp
      })
    );
  }

  private _downloadFile(data: string, fileName: string, extension: string, fileType: string) {
    // Create a Blob from the XML string
    const blob = new Blob([data], {
      type: fileType
    });

    // Create a link element
    const link = document.createElement('a');

    // Create an object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Set the download attribute with the filename
    link.href = url;
    link.download = `${fileName}.${extension}`;

    // Append the link to the DOM (it won't be visible)
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the DOM after the download
    document.body.removeChild(link);

    // Revoke the object URL to free memory
    URL.revokeObjectURL(url);
  }

  private _handleOperationLogsDownload(logEntry) {
    this.store$.dispatch(
      downloadWebServiceLogData({
        correlationId: logEntry.correlationId,
        operationName: logEntry.operationName,
        timeStamp: logEntry.timeStamp
      })
    );
  }
}
