import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { WebServiceLogsService } from '../services/webservice-logs.service';
import { downloadWebServiceParametersData, downloadWebServiceLogData } from './webservice-logs.actions';

@Injectable()
export class WebServiceLogsEffects {
  storeKey = 'webservice-logs-grid';

  constructor(private readonly actions$: Actions, private readonly webserviceLogsService: WebServiceLogsService) {}

  downloadWebServiceLogData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadWebServiceLogData),
        tap(async ({ operationName, correlationId, timeStamp }) => {
          await this.webserviceLogsService.downloadWebServiceLogData(operationName, correlationId, timeStamp);
        })
      ),
    { dispatch: false }
  );

  downloadWebServiceLogParametersData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadWebServiceParametersData),
        tap(async ({ operationName, correlationId, timeStamp }) => {
          await this.webserviceLogsService.downloadWebServiceLogParameters(operationName, correlationId, timeStamp);
        })
      ),
    { dispatch: false }
  );
}
