export enum CustomRoutes {
  AccesNonAutorise = 'acces-non-autorise',
  Associations = 'associations',
  DownloadRepository = 'download-repository',
  DownloadPasarelle = 'download-pasarelle',
  Statisiques = 'statistiques',
  ListeBl = 'liste-bl',
  ListBlRecu = 'liste-bl-recu',
  ValidateIndicateurs = 'validate-indicateurs',
  MonitoringWebServices = 'monitoring-webservices',
}
