export enum PermissionAction {
  Dashboard = 'accueil',
  DownloadProductReference = 'telecharger-referentiel-produits',
  DownloadPasarelle = 'telecharger-pasarelle',
  PublishPasarelle = 'publisher-pasarelle',
  ExportAllIndicators = 'exporter-tous-indicateurs',
  ValidateAssociationsIndicators = 'valider-indicateurs-associations',
  ListStatistics = 'statistiques',
  ListAssociations = 'liste-associations',
  UpdateAssociations = 'update-associations',
  ListBonLivraison = 'liste-bl',
  ListWebServiceLogs = 'liste-webservice-logs',
}
