import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterTypesEnum,
  ListOrder,
  TcConfigTypes,
  TcDataProviderType,
  TcSmartFilterConfig,
} from '@tc/abstract';
import { TcSmartGridComponent } from '@tc/advanced-components';
import {
  DEFAULT_TC_FILTER_STATE_KEY,
  TcFormlyComponent,
  TcFormlyWrapper,
  TcGridCellComponent,
  TcTranslateService,
  formlyColumn,
  formlyControl,
  formlyRow,
  getTcFilters,
} from '@tc/core';
import { NgRxTcDataState } from '@tc/data-store';
import { ITcRestApiRoutesProvider } from '@tc/rest-api';
import { selectByKey } from '@tc/store';
import { hasValue } from '@tc/utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';

import { ViewEncapsulation } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import * as R from 'ramda';
import { UserModel } from '../../../../../../modules/auth/models/user.model';
import { getAuthenticatedUser } from '../../../../../../modules/auth/store/auth.selectors';
import {
  DEFAULT_GRID_HEADER_HEIGHT,
  GRID_ROW_HEIGHT_SMALL,
} from '../../../../../shared/utils/constants';
import { getBankIdFromUserDetails } from '../../../../../shared/utils/user.utils';
import { loadAssociationsStats } from '../../../store/associations.actions';
import {
  AssociationCategorie,
  AssociationContact,
  NiveauParteneriatAssociation,
} from '../../../types';

@Component({
  selector: 'app-associations-grid',
  templateUrl: './associations-grid.component.html',
  styleUrls: ['./associations-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssociationsGridComponent
  extends TcSmartGridComponent
  implements OnInit, OnDestroy
{
  storeKey = 'associations-grid';
  filterConfig: TcSmartFilterConfig;
  fields: FormlyFieldConfig[];

  private filterStore$: Observable<NgRxTcDataState>;
  private subscription = new Subscription();

  constructor(
    store$: Store<any>,
    private readonly routeProvider: ITcRestApiRoutesProvider,
    private readonly translateService: TcTranslateService
  ) {
    super(store$);
    this.filterStore$ = store$.pipe(
      select(DEFAULT_TC_FILTER_STATE_KEY),
      filter(hasValue),
      distinctUntilChanged()
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async ngOnInit() {
    // Subscription to update Association Stats tables
    const statsSub = selectByKey(
      getTcFilters,
      this.filterStore$,
      this.storeKey
    ).subscribe((curFilter) => {
      // Only request stats if the filter is not empty. The initial filter is empty and
      // we end up with errored stats.
      if (!R.isEmpty(curFilter)) {
        this.store$.dispatch(loadAssociationsStats(curFilter));
      }
    });

    const authenticatedUser = await this.store$
      .select(getAuthenticatedUser)
      .pipe(take(1))
      .toPromise();

    // Authenticated user contains extra details that we require if the user is a bank
    // to set the default bank on the bank filter and disable the control.
    // For admin, the control is enabled and all banks are present in the control.
    this.setFilterConfig(authenticatedUser?.additionalDetails);

    this.subscription.add(statsSub);

    this.listConfig = {
      configType: TcConfigTypes.TcGrid,
      storeKey: this.storeKey,
      cssClass: 'associations-grid',
      gridOptions: {
        headerHeight: DEFAULT_GRID_HEADER_HEIGHT,
        rowHeight: GRID_ROW_HEIGHT_SMALL,
      },
      dataProvider: {
        configType: TcConfigTypes.TcDataProvider,
        providerType: TcDataProviderType.RestApi,
        dataSet: this.routeProvider.getRoutes().associations,
        fields: '',
      },
      columnNumberPerDevice: {
        extraSmallDevice: 20,
        smallDevice: 20,
        mediumDevice: 20,
        largeDevice: 20,
        extraLargeDevice: 20,
        extraExtraLargeDevice: 20,
      },
      columns: this._getGridColumns(authenticatedUser),
      filterConfig: this.filterConfig,
    };

    super.ngOnInit();
  }

  private _getGridColumns(authenticatedUser?: UserModel): ColDef[] {
    const gridColumns: ColDef[] = [];

    gridColumns.push(
      {
        field: 'code',
        minWidth: 180,
        maxWidth: 180,
        resizable: false,
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) =>
          isDescending ? -1 : 1,
      },
      {
        field: 'nom',
        minWidth: 180,
        resizable: false,
        hide: true,
        initialHide: true,
      }
    );

    if (authenticatedUser?.roles?.every((role) => role.role !== 'BANK')) {
      gridColumns.push({
        field: 'banqueNom',
        minWidth: 170,
        maxWidth: 170,
        resizable: false,
        hide: true,
        initialHide: true,
      });
    }

    gridColumns.push(
      {
        field: 'categorie',
        cellRenderer: (params: any) =>
          this.associationsGridCellRenderer('categorie', params.value),
        minWidth: 95,
        maxWidth: 95,
        resizable: false,
      },
      {
        field: 'contacts',
        cellRenderer: (params: any) =>
          this.associationsGridCellRenderer('contacts', params.value),
        minWidth: 250,
        maxWidth: 250,
        resizable: false,
      },
      {
        field: 'niveauParteneriatActuel',
        cellRenderer: TcGridCellComponent.ComboRenderer,
        resizable: false,
        cellRendererParams: {
          possibleValues: this.getLevelOptions()
            .slice(1)
            .filter((d) => !!d),
          disabled: !this.isAMSPartnershipEditable(authenticatedUser),
        },
      },
      {
        field: 'niveauParteneriatNouveau',
        resizable: false,
        cellRenderer: (params: any) =>
          this.associationNiveauParteneriatCellRendered(params.value),
        minWidth: 150,
        tooltipValueGetter: (params) =>
          this.associationNiveauParteneriatCellRendered(params.value),
      }
    );
    return gridColumns;
  }

  private isAMSPartnershipEditable(authenticatedUser) {
    return authenticatedUser.roles.some((role) => role.role === 'ADM');
  }

  private associationsGridCellRenderer(
    field: string,
    value: string | AssociationContact[]
  ): string {
    switch (field) {
      case 'categorie': {
        return this.translateService.instant(
          `${this.storeKey}.table.values.${field}.${value}`
        );
      }
      case 'contacts': {
        if (value && value.length) {
          var administratorLines = (value as AssociationContact[]).map(
            (contact) => `${contact.nom} ${contact.prenom} (${contact.email})`
          );
          var title = administratorLines.join('\n');
          return administratorLines
            .slice(0, 2)
            .map((administratorLine) => {
              return `<div class="administrator-line" title="${title}">${administratorLine}</div>`;
            })
            .join('');
        }
      }
      default:
        return '';
    }
  }

  private getLevelOptions() {
    return [
      {
        value: -1,
        label: this.translateService.instant('globalLabels.tous'),
      },
      {
        value: NiveauParteneriatAssociation.Aucun,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.aucun'
        ),
      },
      {
        value: NiveauParteneriatAssociation.TicadiSeul,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.ticadi-seul'
        ),
      },
      {
        value: NiveauParteneriatAssociation.ProxidonSeul,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.proxidon-seul'
        ),
      },
      ,
      {
        value: NiveauParteneriatAssociation.ProxidonAndTicadi,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.proxidon-and-ticadi'
        ),
      },
      ,
      {
        value: NiveauParteneriatAssociation.CompteProxidonDeTest,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.compte-proxidon-de-test'
        ),
      },
      ,
      {
        value:
          NiveauParteneriatAssociation.CompteProxidonBAAggisantCommeUneAssociation,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.compte-proxidon-ba-aggisant-comme-une-association'
        ),
      },
      ,
      {
        value: NiveauParteneriatAssociation.PacobaSeul,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.pacoba-seul'
        ),
      },
      ,
      {
        value: NiveauParteneriatAssociation.PacobaAndTicadi,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.pacoba-and-ticadi'
        ),
      },
      ,
      {
        value: NiveauParteneriatAssociation.PacobaAndTicadiAndProxidon,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.pacoba-and-ticadi-and-proxidon'
        ),
      },
      ,
      {
        value: NiveauParteneriatAssociation.PacobaAndProxidon,
        label: this.translateService.instant(
          'associations-niveau-parteneriat.pacoba-and-proxidon'
        ),
      },
    ];
  }

  private associationNiveauParteneriatCellRendered(
    value: NiveauParteneriatAssociation
  ): string {
    switch (value) {
      case NiveauParteneriatAssociation.Aucun: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.aucun`
        );
      }
      case NiveauParteneriatAssociation.TicadiSeul: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.ticadi-seul`
        );
      }
      case NiveauParteneriatAssociation.ProxidonSeul: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.proxidon-seul`
        );
      }
      case NiveauParteneriatAssociation.ProxidonAndTicadi: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.proxidon-and-ticadi`
        );
      }
      case NiveauParteneriatAssociation.CompteProxidonDeTest: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.compte-proxidon-de-test`
        );
      }
      case NiveauParteneriatAssociation.CompteProxidonBAAggisantCommeUneAssociation: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.compte-proxidon-ba-aggisant-comme-une-association`
        );
      }
      case NiveauParteneriatAssociation.PacobaSeul: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.pacoba-seul`
        );
      }
      case NiveauParteneriatAssociation.PacobaAndTicadi: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.pacoba-and-ticadi`
        );
      }
      case NiveauParteneriatAssociation.PacobaAndTicadiAndProxidon: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.pacoba-and-ticadi-and-proxidon`
        );
      }
      case NiveauParteneriatAssociation.PacobaAndProxidon: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.pacoba-and-proxidon`
        );
      }

      default: {
        return this.translateService.instant(
          `associations-niveau-parteneriat.unknown`
        );
      }
    }
  }

  setFilterConfig(userDetails: { [key: string]: any }) {
    // If the logged in user is a bank, we need to set the default selected value to the bank
    // and disable the select form
    const bankId = getBankIdFromUserDetails(userDetails) ?? '';
    this.fields = [
      formlyColumn({
        fields: [
          formlyRow({
            fields: [
              formlyControl({
                key: 'banqueId',
                type: TcFormlyComponent.TcSmartSelect,
                defaultValue: bankId,
                templateOptions: {
                  subscriptSizing: 'dynamic',
                  disabled: !!bankId,
                  clearButtonEnabled: false,
                  filterType: FilterTypesEnum.Equal,
                  multiple: false,
                  labelFieldName: 'nom',
                  valueFieldName: 'id',
                  defaultValue: '',
                  defaultValueLabel: this.translateService.instant(
                    `${this.storeKey}.filter.values.banqueId.allBanques`
                  ),
                  dataProvider: {
                    configType: TcConfigTypes.TcDataProvider,
                    providerType: TcDataProviderType.RestApi,
                    dataSet: this.routeProvider.getRoutes().banques,
                    sortOrder: {
                      key: 'nom',
                      order: ListOrder.Asc,
                    },
                  },
                },
                lgColSpan: 3,
                xxlColSpan: 3,
              }),
              formlyControl({
                key: 'categorie',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: AssociationCategorie.TousCategories,
                templateOptions: {
                  hideSingleSelectionIndicator: true,
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: AssociationCategorie.TousCategories,
                      label: this.translateService.instant(
                        `associationCategorie.tousCategories`
                      ),
                    },
                    {
                      value: AssociationCategorie.Categorie1,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie1}`
                      ),
                    },
                    {
                      value: AssociationCategorie.Categorie2,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie2}`
                      ),
                    },
                    {
                      value: AssociationCategorie.Categorie3,
                      label: this.translateService.instant(
                        `associationCategorie.${AssociationCategorie.Categorie3}`
                      ),
                    },
                  ],
                },
                colSpan: 2,
              }),
              formlyControl({
                key: 'active',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: true,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: [
                    {
                      value: '',
                      label: this.translateService.instant(`globalLabels.tous`),
                    },
                    {
                      value: true,
                      label: this.translateService.instant(`globalLabels.oui`),
                    },
                    {
                      value: false,
                      label: this.translateService.instant(`globalLabels.non`),
                    },
                  ],
                },
                colSpan: 1,
              }),
              formlyControl({
                key: 'niveauParteneriatActuel',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: -1,
                colSpan: 2,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: this.getLevelOptions(),
                },
              }),
              formlyControl({
                key: 'niveauParteneriatNouveau',
                type: TcFormlyComponent.FormlySelect,
                defaultValue: -1,
                colSpan: 2,
                templateOptions: {
                  clearButtonEnabled: false,
                  options: this.getLevelOptions(),
                },
              }),
            ],
          }),
        ],
        colSpan: 12,
        wrappers: [TcFormlyWrapper.ExpansionPanel],
        templateOptions: {
          label: 'filter-options',
          displayWrapperOnlyOnMobile: true,
        },
      }),
    ];

    this.filterConfig = {
      configType: TcConfigTypes.TcFilter,
      storeKey: this.storeKey,
      fields: this.fields,
      isPersistant: false,
    };
  }
}
