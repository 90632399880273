import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TcNavMenuNodes, TcTranslateService } from '@tc/core';
import { getAbilities } from '@tc/permissions';
import { take, tap } from 'rxjs/operators';

import {
  initMenu,
  setMenuItems,
} from '../../../../modules/menu/store/menu.actions';
import { CustomRoutes } from '../../../typings/custom-routes.enum';
import { PermissionAction } from '../../../typings/permission-action.enum';
import { PermissionSubject } from '../../../typings/permission-subject.enum';
import { IndicatorsService } from '../../indicators/services/indicators.service';

@Injectable()
export class MenuEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly indicatorsService: IndicatorsService,
    private readonly translate: TcTranslateService
  ) {}

  initMenu = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initMenu),
        tap(async () => {
          const items = await this.getMenuItems();
          this.store$.dispatch(setMenuItems({ items }));
        })
      ),
    { dispatch: false }
  );

  private async getMenuItems() {
    const abilities = await this.store$
      .select(getAbilities)
      .pipe(take(1))
      .toPromise();

    /**
     * NOTE: If this array gets changed probably the array form apps\frontend\src\app\custom\pages\home-page\home.component.ts:126:7
     *       needs to be updated
     */
    const items: TcNavMenuNodes[] = [
      // TODO in future task: Set the proper routes and permissions for the below routes
      {
        label: 'home-nav.title',
        route: 'home',
        hidden: abilities.cannot(
          PermissionAction.Dashboard,
          PermissionSubject.All
        ),
      },
      {
        label: 'download-repository-nav.title',
        route: CustomRoutes.DownloadRepository,
        hidden: abilities.cannot(
          PermissionAction.DownloadProductReference,
          PermissionSubject.All
        ),
      },
      {
        label: 'export-indicators-nav.title',
        onSelected: () => this.indicatorsService.exportIndicators(),
        hidden: abilities.cannot(
          PermissionAction.ExportAllIndicators,
          PermissionSubject.All
        ),
      },
      {
        label: 'validate-indicators-nav.title',
        route: CustomRoutes.ValidateIndicateurs,
        hidden: abilities.cannot(
          PermissionAction.ValidateAssociationsIndicators,
          PermissionSubject.All
        ),
      },
      {
        label: 'statistics-nav.title',
        route: CustomRoutes.Statisiques,
        hidden: abilities.cannot(
          PermissionAction.ListStatistics,
          PermissionSubject.All
        ),
      },
      {
        label: 'associations-nav.title',
        class: 'menu-item-bold',
        route: CustomRoutes.Associations,
        hidden: abilities.cannot(
          PermissionAction.ListAssociations,
          PermissionSubject.All
        ),
      },
      {
        label: 'bl-nav.title',
        route: CustomRoutes.ListeBl,
        hidden: abilities.cannot(
          PermissionAction.ListBonLivraison,
          PermissionSubject.All
        ),
      },
      {
        label: 'bl-received-nav.title',
        route: CustomRoutes.ListBlRecu,
        hidden: abilities.cannot(
          PermissionAction.ListBonLivraison,
          PermissionSubject.All
        ),
      },
      {
        label: 'monitoring-webservices.title',
        route: CustomRoutes.MonitoringWebServices,
        hidden: abilities.cannot(
          PermissionAction.ListWebServiceLogs,
          PermissionSubject.All
        ),
      },
    ];

    return items;
  }
}
