import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPageState } from '@tc/store';
import { AmsPage } from '../../shared/directives/ams-page';

@Component({
  selector: 'app-webservice-logs-page',
  templateUrl: './webservice-logs-page.component.html',
  styleUrls: ['./webservice-logs-page.component.scss'],
})
export class WebServiceLogsPageComponent extends AmsPage implements OnInit {
  constructor(store: Store<any>) {
    super(store);
  }

  ngOnInit(): void {
    const pageInfo: IPageState = {
      title: 'webservice-logs-page.title',
      displayPageTitle: true,
    };

    this.pageInfo = pageInfo;
    super.ngOnInit();
  }
}
