import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TcFormComponent, TcTranslateService } from '@tc/core';

@Component({
  selector: 'app-webservice-logs-grid-filter',
  templateUrl: './webservice-logs-grid-filter.component.html',
  styleUrls: ['./webservice-logs-grid-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WebServiceLogsGridFilterComponent
  extends TcFormComponent<any>
  implements OnInit
{
  gridStoreKey = 'webservice-logs-grid';

  constructor(translate: TcTranslateService, elem: ElementRef) {
    super(translate, elem);
  }
}
