import { createAction, props } from '@ngrx/store';

export const downloadWebServiceLogData = createAction(
  '[WebService Logs] Download WebService Log Data',
  props<{
    correlationId: string;
    operationName: string;
    timeStamp: string;
  }>()
);

export const downloadWebServiceParametersData = createAction(
  '[WebService Logs] Download WebService Exception Data',
  props<{
    correlationId: string;
    operationName: string;
    timeStamp: string;
  }>()
);
